exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-ace-payroll-js": () => import("./../../../src/pages/ace-payroll.js" /* webpackChunkName: "component---src-pages-ace-payroll-js" */),
  "component---src-pages-add-ons-js": () => import("./../../../src/pages/add-ons.js" /* webpackChunkName: "component---src-pages-add-ons-js" */),
  "component---src-pages-akahu-js": () => import("./../../../src/pages/akahu.js" /* webpackChunkName: "component---src-pages-akahu-js" */),
  "component---src-pages-asb-js": () => import("./../../../src/pages/asb.js" /* webpackChunkName: "component---src-pages-asb-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-ca-index-js": () => import("./../../../src/pages/ca/index.js" /* webpackChunkName: "component---src-pages-ca-index-js" */),
  "component---src-pages-ca-untangling-holidays-act-js": () => import("./../../../src/pages/ca/untangling-holidays-act.js" /* webpackChunkName: "component---src-pages-ca-untangling-holidays-act-js" */),
  "component---src-pages-ca-webinar-series-js": () => import("./../../../src/pages/ca/webinar-series.js" /* webpackChunkName: "component---src-pages-ca-webinar-series-js" */),
  "component---src-pages-call-booked-js": () => import("./../../../src/pages/call-booked.js" /* webpackChunkName: "component---src-pages-call-booked-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-farmers-payroll-js": () => import("./../../../src/pages/farmers-payroll.js" /* webpackChunkName: "component---src-pages-farmers-payroll-js" */),
  "component---src-pages-franchise-payroll-js": () => import("./../../../src/pages/franchise-payroll.js" /* webpackChunkName: "component---src-pages-franchise-payroll-js" */),
  "component---src-pages-guides-switching-payroll-systems-js": () => import("./../../../src/pages/guides/switching-payroll-systems.js" /* webpackChunkName: "component---src-pages-guides-switching-payroll-systems-js" */),
  "component---src-pages-holidays-act-compliance-js": () => import("./../../../src/pages/holidays-act-compliance.js" /* webpackChunkName: "component---src-pages-holidays-act-compliance-js" */),
  "component---src-pages-horticulture-js": () => import("./../../../src/pages/horticulture.js" /* webpackChunkName: "component---src-pages-horticulture-js" */),
  "component---src-pages-hospitality-js": () => import("./../../../src/pages/hospitality.js" /* webpackChunkName: "component---src-pages-hospitality-js" */),
  "component---src-pages-icnzb-js": () => import("./../../../src/pages/icnzb.js" /* webpackChunkName: "component---src-pages-icnzb-js" */),
  "component---src-pages-ims-payroll-js": () => import("./../../../src/pages/ims-payroll.js" /* webpackChunkName: "component---src-pages-ims-payroll-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interactive-demo-js": () => import("./../../../src/pages/interactive-demo.js" /* webpackChunkName: "component---src-pages-interactive-demo-js" */),
  "component---src-pages-karmly-js": () => import("./../../../src/pages/karmly.js" /* webpackChunkName: "component---src-pages-karmly-js" */),
  "component---src-pages-leave-requests-js": () => import("./../../../src/pages/leave-requests.js" /* webpackChunkName: "component---src-pages-leave-requests-js" */),
  "component---src-pages-lp-hourly-employees-js": () => import("./../../../src/pages/lp/hourly-employees.js" /* webpackChunkName: "component---src-pages-lp-hourly-employees-js" */),
  "component---src-pages-lp-payroll-js": () => import("./../../../src/pages/lp/payroll.js" /* webpackChunkName: "component---src-pages-lp-payroll-js" */),
  "component---src-pages-lp-payroll-timeclock-js": () => import("./../../../src/pages/lp/payroll-timeclock.js" /* webpackChunkName: "component---src-pages-lp-payroll-timeclock-js" */),
  "component---src-pages-manufacturing-js": () => import("./../../../src/pages/manufacturing.js" /* webpackChunkName: "component---src-pages-manufacturing-js" */),
  "component---src-pages-mobile-js": () => import("./../../../src/pages/mobile.js" /* webpackChunkName: "component---src-pages-mobile-js" */),
  "component---src-pages-myhr-js": () => import("./../../../src/pages/myhr.js" /* webpackChunkName: "component---src-pages-myhr-js" */),
  "component---src-pages-myob-payroll-js": () => import("./../../../src/pages/myob-payroll.js" /* webpackChunkName: "component---src-pages-myob-payroll-js" */),
  "component---src-pages-offers-myhr-js": () => import("./../../../src/pages/offers/myhr.js" /* webpackChunkName: "component---src-pages-offers-myhr-js" */),
  "component---src-pages-partner-directory-js": () => import("./../../../src/pages/partner-directory.js" /* webpackChunkName: "component---src-pages-partner-directory-js" */),
  "component---src-pages-payday-filing-js": () => import("./../../../src/pages/payday-filing.js" /* webpackChunkName: "component---src-pages-payday-filing-js" */),
  "component---src-pages-payroll-glossary-js": () => import("./../../../src/pages/payroll-glossary.js" /* webpackChunkName: "component---src-pages-payroll-glossary-js" */),
  "component---src-pages-payroll-js": () => import("./../../../src/pages/payroll.js" /* webpackChunkName: "component---src-pages-payroll-js" */),
  "component---src-pages-preview-customer-js": () => import("./../../../src/pages/preview/customer.js" /* webpackChunkName: "component---src-pages-preview-customer-js" */),
  "component---src-pages-preview-post-js": () => import("./../../../src/pages/preview/post.js" /* webpackChunkName: "component---src-pages-preview-post-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-recruitment-js": () => import("./../../../src/pages/recruitment.js" /* webpackChunkName: "component---src-pages-recruitment-js" */),
  "component---src-pages-retail-js": () => import("./../../../src/pages/retail.js" /* webpackChunkName: "component---src-pages-retail-js" */),
  "component---src-pages-rostering-js": () => import("./../../../src/pages/rostering.js" /* webpackChunkName: "component---src-pages-rostering-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-small-business-payroll-js": () => import("./../../../src/pages/small-business-payroll.js" /* webpackChunkName: "component---src-pages-small-business-payroll-js" */),
  "component---src-pages-smartly-payroll-js": () => import("./../../../src/pages/smartly-payroll.js" /* webpackChunkName: "component---src-pages-smartly-payroll-js" */),
  "component---src-pages-time-portal-js": () => import("./../../../src/pages/time-portal.js" /* webpackChunkName: "component---src-pages-time-portal-js" */),
  "component---src-pages-timeclock-js": () => import("./../../../src/pages/timeclock.js" /* webpackChunkName: "component---src-pages-timeclock-js" */),
  "component---src-pages-timesheets-js": () => import("./../../../src/pages/timesheets.js" /* webpackChunkName: "component---src-pages-timesheets-js" */),
  "component---src-pages-trades-payroll-js": () => import("./../../../src/pages/trades-payroll.js" /* webpackChunkName: "component---src-pages-trades-payroll-js" */),
  "component---src-pages-try-paye-js": () => import("./../../../src/pages/try-paye.js" /* webpackChunkName: "component---src-pages-try-paye-js" */),
  "component---src-pages-try-payslips-js": () => import("./../../../src/pages/try-payslips.js" /* webpackChunkName: "component---src-pages-try-payslips-js" */),
  "component---src-pages-tutorials-js": () => import("./../../../src/pages/tutorials.js" /* webpackChunkName: "component---src-pages-tutorials-js" */),
  "component---src-pages-xero-js": () => import("./../../../src/pages/xero.js" /* webpackChunkName: "component---src-pages-xero-js" */),
  "component---src-templates-customer-js": () => import("./../../../src/templates/customer.js" /* webpackChunkName: "component---src-templates-customer-js" */),
  "component---src-templates-definition-js": () => import("./../../../src/templates/definition.js" /* webpackChunkName: "component---src-templates-definition-js" */),
  "component---src-templates-download-js": () => import("./../../../src/templates/download.js" /* webpackChunkName: "component---src-templates-download-js" */),
  "component---src-templates-partner-js": () => import("./../../../src/templates/partner.js" /* webpackChunkName: "component---src-templates-partner-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

